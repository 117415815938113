/* eslint-disable react/jsx-props-no-spreading */
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  EmptyScreen,
  Layout,
  Loading,
  ProductCard,
  SEO,
} from '../../../ui'
import useStyles from './account-favourites-style'

function AccountFavouritesView(props) {
  const {
    loading,
    pageLoading,
    favourites = [],
    haveNextPage,
    seoTitle,
    onNextPage,
    onClickTrackEvent,
  } = props
  const styles = useStyles()
  const { t } = useTranslation()
  return (
    <Layout login>
      <SEO title={seoTitle} />
      { pageLoading && <Loading />}
      <div className={styles.containerFluid}>
        <div className={styles.container}>
          {
            _.isEmpty(favourites)
              ? (
                <EmptyScreen
                  // title={t('screens.account.favourites.empty.title')}
                  message={t('screens.account.favourites.empty.message')}
                  button={{
                    text: t('screens.account.favourites.empty.button'),
                    navigate: '/products/',
                  }}
                />
              )
              : (
                <>
                  <div className={styles.list}>
                    {favourites.map((fav, index) => {
                      const {
                        id,
                        favourable = {},
                      } = fav
                      const productCardProps = {
                        colorOptionVariantType: _.get(favourable, 'product.colorOptionVariantType', {}),
                        productId: _.get(favourable, 'product.id'),
                        imageUrl: _.get(favourable, 'defaultImage.versions.webLarge'),
                        title: _.get(favourable, 'product.title'),
                        colorName: _.get(favourable, 'name'),
                        activeCustomLabels: _.get(favourable, 'activeCustomLabels', []),
                        sellPrice: _.get(favourable, 'sellPrice'),
                        originalPrice: _.get(favourable, 'originalPrice'),
                        colorOptionId: _.get(favourable, 'id'),
                        url: _.get(favourable, 'product.canonicalHref'),
                        favourite: fav,
                        sku: favourable,
                        index: index + 1,
                        list: 'Account Favourites',
                        onClickTrackEvent,
                      }
                      return (
                        <div key={`fav-item-${id}`} className={styles.item}>
                          <ProductCard {...productCardProps} />
                        </div>
                      )
                    })}
                  </div>
                  {
                    haveNextPage && (
                      <Button
                        className={styles.button}
                        uppercase
                        // border
                        text={t('screens.account.favourites.buttons.next')}
                        onClick={onNextPage}
                        disabled={loading}
                      />
                    )
                  }
                </>
              )
          }
        </div>
      </div>
    </Layout>
  )
}

export default AccountFavouritesView
