/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-alert */
/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash'
import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
  cancelRequest,
  useAuth,
  useFavourites,
  useSystemSettings,
} from 'react-omnitech-api'
import { useAnalytics, useLink } from '../../../hook'
import AccountFavouritesView from './account-favourites-view'

function AccountFavouritesController() {
  // prepare hook
  const { fetchFavourites } = useFavourites()
  const { auth } = useAuth()
  const { t } = useTranslation()
  const { navigate } = useLink()
  const { trackEvent, getProductParams } = useAnalytics()
  const { getSystemSetting } = useSystemSettings()

  // internal state
  const [pageLoading, setPageLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [favourites, setFavourites] = useState([])
  const [isFirstFetch, setIsFirstFetch] = useState(true)
  const [nextPageFetch, setNextPageFetch] = useState(null)
  const [favouritesUpdated, setFavouritesUpdated] = useState(false)
  // const colorOptionIDInputRef = useRef(null)

  // local variable
  const seoTitle = t('screens.accountFavourites.seo.title')

  const getFavourites = useCallback(() => {
    const fetch = nextPageFetch || fetchFavourites
    const promise = fetch({
      includes: [
        'favourable_default',
        'color_options.product',
        'color_options.active_custom_labels',
        'color_options.price_details',
        'products.color_option_variant_type',
        'products.category_ids',
        'products.brand_ids',
      ],
    })

    async function loadData() {
      setPageLoading(_.isEmpty(favourites))
      setLoading(true)
      setIsFirstFetch(false)

      try {
        const { favourites: data, next } = await promise

        if (favouritesUpdated) setFavourites(data)
        else setFavourites([...favourites, ...data])

        setNextPageFetch(_.isFunction(next) ? () => next : null)
      } catch (err) {
        alert(err)
      } finally {
        setPageLoading(false)
        setLoading(false)
        setFavouritesUpdated(false)
      }
    }

    loadData()
  }, [favourites, favouritesUpdated, fetchFavourites, nextPageFetch])

  const onNextPage = getFavourites
  function trackProductsImpression() {
    const products = _.map(favourites, ({ id, favourable }, idx) => ({
      ...getProductParams(favourable),
      list: 'Account Favourites',
      position: (idx + 1),
    }))
    trackEvent('viewProductImpression', {}, { products, title: 'Account Favourites' })
  }

  function handleClickTrackEvent(eventName, product) {
    trackEvent(eventName, {}, { product })
  }

  /**
   * when the page is loaded
   * without user logged in, redirect to login page
   */
  useEffect(() => {
    if (!auth.userId) {
      navigate(
        '/login/',
        {
          state: {
            redirectUrl: '/account/favourites/',
          },
          replace: true,
        },
      )
    }
  }, [])

  useEffect(() => (function cleanUp() {
    cancelRequest.cancelAll([
      'fetchFavourites',
    ])
  }), [])

  useEffect(() => {
    if (_.isEmpty(favourites)) return
    trackProductsImpression()
  }, [favourites])

  useEffect(() => {
    if (isFirstFetch || favouritesUpdated) getFavourites()
  }, [favourites, favouritesUpdated, getFavourites, isFirstFetch])

  const viewProps = {
    pageLoading,
    loading,
    favourites,
    haveNextPage: _.isFunction(nextPageFetch),
    seoTitle,
    onClickTrackEvent: handleClickTrackEvent,
    onNextPage,
  }
  return (
    <AccountFavouritesView {...viewProps} />
  )
}

export default AccountFavouritesController
