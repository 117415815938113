import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  containerFluid: {
    padding: [[30, 0]],
    width: '100%',
    textAlign: 'left',
    overflow: 'hidden',
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      padding: [[60, 0]],
      width: '100%',
      textAlign: 'left',
    },
  },
  container: {
    padding: '0 2rem',
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    margin: [[0, '-1rem']],
  },
  item: {
    width: '50%',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      width: '25%',
    },
  },
}))

export default useStyles
